import React, { useEffect, useRef, useState, MouseEvent, useCallback } from 'react'
import debounce from 'lodash.debounce'
import { Component } from '../../types/components'
import IsBrowser from '../../utils/is-browser'
import { AccordionCollapsedIcon, AccordionExpandedIcon, Button } from '../elements'

const ACCORDION_CLASS = 'c__accordion'

export default function Accordion({ children, title, className = '' }: Component.Accordion) {
  const contentReference = useRef<HTMLDivElement>()
  const [isOpen, setIsOpen] = useState(false)
  const [maxHeight, setMaxHeight] = useState(0)

  const calculateHeight = useCallback(() => {
    if (contentReference.current && IsBrowser()) {
      const bounding = contentReference.current.getBoundingClientRect()
      setMaxHeight(bounding.height)
    }
  }, [])

  useEffect(() => {
    calculateHeight()

    const debouncedCalculate = debounce(calculateHeight, 200)

    if (IsBrowser()) {
      window.addEventListener('resize', debouncedCalculate)
    }

    return () => {
      debouncedCalculate.cancel()

      if (IsBrowser()) {
        window.removeEventListener('resize', debouncedCalculate)
      }
    }
  }, [calculateHeight])

  const onAccordionToggle = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setIsOpen(!isOpen)

    setTimeout(() => {
      calculateHeight()
    }, 50)
  }

  return (
    <div className={`${ACCORDION_CLASS} ${isOpen ? `${ACCORDION_CLASS}--visible` : ''} ${className}`}>
      <Button type="button" variant="link" onClick={onAccordionToggle}>
        {title}
        {isOpen ? <AccordionExpandedIcon /> : <AccordionCollapsedIcon />}
      </Button>
      <div className={`${ACCORDION_CLASS}__panel`} style={{ maxHeight: `${maxHeight}px` }}>
        <div className={`${ACCORDION_CLASS}__panel__content u__mc--vertical`} ref={contentReference}>
          {children}
        </div>
      </div>
    </div>
  )
}
