import React from 'react'
import { Grouping } from '../../types/grouping'

export default function NeedAssistance({ title, emailAddress, emailPreface }: Grouping.NeedAssistance) {
  return (
    <div className="c__need-assistance u__mc--vertical">
      <p className="body-1">{title}</p>
      <p className="body-2">
        {emailPreface} <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
      </p>
    </div>
  )
}
