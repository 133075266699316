import React, { ReactElement, useEffect } from 'react'
import Accordion from '../components/components/accordion'
import PageLayout from '../components/components/page-layout'
import { Section } from '../components/elements'
import { PageTitle } from '../components/grouping'
import NeedAssistance from '../components/grouping/need-assistance'
import { VIDEO_PATH } from '../types/constants'
import { Page } from '../types/page'
import isOnline from '../utils/get-online-status'
import IsBrowser from '../utils/is-browser'

const VideoFAQFooter = () => (
  <p>
    Download the{' '}
    <a href={VIDEO_PATH} download target="_blank" rel="noopener noreferrer">
      How to apply the label
    </a>{' '}
    video.
  </p>
)

const WatchHowToApplyVideo = () =>
  isOnline ? (
    <div>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video autoPlay controls crossOrigin="anonymous" style={{ width: '100%', border: '1px solid lightgrey' }}>
        <source src={VIDEO_PATH} type="video/mp4" />
      </video>
    </div>
  ) : (
    <></>
  )

export default function HelpTemplate({
  pageContext: {
    language,
    pageTitle: { subTitle, title },
    faqs,
    footer: { title: footerTitle, emailAddress, emailPreface },
  },
}: Page.TemplateHelpProperties): ReactElement {
  useEffect(() => {
    if (IsBrowser()) {
      window.dispatchEvent(new Event('resize'))
    }
  }, [])

  return (
    <PageLayout lang={language}>
      <Section className="c__help-template">
        <PageTitle subTitle={subTitle} title={title} alignment="center" />
        <div className="c__faq-wrapper container">
          <div className="row flex justify-center">
            <div className="c__accordian col-75">
              {faqs.map(({ title: accordionTitle, content, id }) => (
                <Accordion title={accordionTitle} key={id}>
                  <p>{content}</p>
                  {id === '031ec609-85bd-478e-b1fe-2f611442588e' && <VideoFAQFooter />}
                  {id === '5ea384fb-88a1-4c78-a853-b016e4b680b3' && <WatchHowToApplyVideo />}
                </Accordion>
              ))}
            </div>
          </div>
        </div>
        <NeedAssistance title={footerTitle} emailAddress={emailAddress} emailPreface={emailPreface} />
      </Section>
    </PageLayout>
  )
}
